#startDate {
    font-size: 1.2rem;
}

#endDate {
    font-size: 1.2rem;
}

@media only screen and (max-width: 321px) {
    #refund-table-container > div > div > table > tfoot > tr > td {
        padding: 0px;
    }
    #refund-table-container > div > div > table > tfoot > tr > td > div > div > div {
        padding: 0px 5px;
    }
}