.listItemText span {
    font-size: 1.5rem;
}

.MuiListItemIcon-root {
    min-width: none;
}

@media only screen and (max-width: 281px) {
    .listItemText span {
        font-size: 1.2rem;
    }
  }