.spinner {
    width: 150px;
    height: 150px;
    animation-name: spin;
    animation-duration: 1500ms;
    animation-iteration-count: infinite;
    animation-timing-function: linear; 
    align-items: center;
    justify-content: center;
    border-radius: 100vh;
    z-index: 1000;
    position: fixed;
    top: 45%;
    left: 45%;
}
  
@keyframes spin {
    from {
        transform:rotate(0deg);
    }
    to {
        transform:rotate(360deg);
    }
}
