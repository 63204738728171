@media only screen and (min-device-width: 320px) and (max-device-width: 568px)
and (-webkit-device-pixel-ratio: 2) and (device-aspect-ratio: 40/71) and (orientation:portrait)
{
    #transaction-card {
        min-height: 333px;
    }
}

@media screen and (device-width: 360px) and (device-height: 640px) and (-webkit-device-pixel-ratio: 3)
{
    #transaction-card {
        min-height: calc(100% - 220px);
    }
}
