html, body {
    margin: 0; 
    height: 100%; 
    overflow-x: hidden;
}

body {
    background-size: auto;
    background-color: #00468E;
    background-blend-mode: multiply;
    height: 100%; 
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

@media only screen and (min-device-width: 320px) and (max-device-width: 568px)
and (-webkit-device-pixel-ratio: 2) and (device-aspect-ratio: 40/71) and (orientation:portrait)
{
    .MuiTypography-root.makeStyles-title-28.MuiTypography-h3 {
       font-size: 1.5rem; 
    }
    h1 {
        font-size: 2rem !important;
    }
    h3 {
        font-size: 2rem !important;
    }
    h4 {
        font-size: 1.4rem !important;
    }
    h5 {
        font-size: 1rem !important;
    }
    #event-title {
        justify-content: flex-start;
    }
    .card-spinning {
        top: 0px;
    }
}